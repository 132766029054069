/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";
import { stringify } from "qs";

// 获取商品列表
const getItemList = async (params) =>
  request({
    url: `/frontCategory/getPagerItemByFrontCategoryId?${stringify(params)}`,
    method: "get",
  });

const getTotalItemList = async (params) =>
  request({
    url: `/cartLine/getActivityItemList?${stringify(params)}`,
    method: "get",
  });

export { getItemList, getTotalItemList };

<!--
 * @Description: 
 * @Author: rodchen
 * @Date: 2021-07-20 21:10:51
 * @LastEditTime: 2021-08-26 08:27:20
 * @LastEditors: rodchen
-->
<template>
  <div id="theme">
    <div class="saerch">
      <!-- <Search /> -->
    </div>
    <div class="main">
      <div class="main-top">
        <div class="activity">
        </div>
        <!-- <div>综合</div>
        <div style="display: flex; justify-content: center">
          按价格&nbsp;<span class="main-top-price">
            <van-icon name="arrow-up" size="12" />
            <van-icon name="arrow-down" size="12" />
          </span>
        </div>
        <div>按销量</div> -->
      </div>
      <div class="main-bottom">
        <van-grid :column-num="2" class="fourInarow-details">
          <van-grid-item
            v-for="item in items"
            :key="item.id"
            @click="() => shopdetails(item)"
          >
            <van-image :src="findUrl(item)" width="45%" height="71" />
            <p class="goods-title">{{ item.name }}</p>
            <p class="goods-price">￥{{ item.price }}</p>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { getItemList, getTotalItemList } from "./service";

export default {
  data() {
    return {
      status: 1,
      items: [],
    };
  },
  mounted() {
    // this.getThemeItemList();
    this.queryItemList(this.$route.query.activityNo) 

  },
  methods: {
    getThemeItemList() {
      if (this.$route.query.themId) {
        getItemList({ "qp-frontCategoryId-eq": this.$route.query.themId }).then(
          (res) => {
            if (res.status === "0") {
              this.items = res.data.items;
            }else{
              Toast.fail(res.msg)
            }
          }
        ).catch((err)=>{
            return err
          })
      // } else {
      //   getTotalItemList().then((res) => {
      //     if (res.status === "0") {
      //       this.items = res.data.items;
      //     }
      //   });
      }
    },
    queryItemList(activityNo) {
      getTotalItemList({ "qp-activityNo-eq": activityNo }).then((res) => {
        if (res.status === "0") {
          this.items = res.data;
        }else{
          Toast.fail(res.msg)
        }
      }).catch((err)=>{
        return err
      })
    },
    findUrl(item) {
      return item?.mediaList ? item.mediaList[0].url : undefined;
    },
    shopdetails(item) {
      const { itemCode, storeCode } = item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode, storeCode },
      });
    },
  },
};
</script>

<style lang="less">
#theme {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //   margin-top: 40px;

  .main-top {
    display: flex;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    .activity {
      font-size: 12px;
      line-height: 15px;
      color: rgb(255, 255, 255);
      background: #f58f98;
    }
    .main-top-price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 10px;
      line-height: 40px;
    }
  }

  .main-top > div {
    //width: 33%;
    // text-align: center;
  }

  .fourInarow-details {
    .goods-title {
      width: 100%;
      height: 18px;
      line-height: 14px;
      font-size: 12px;
    }
    .goods-price {
      margin: 8px 0;
      width: 100%;
      height: 16px;
      line-height: 16px;
      color: red;
    }
    /deep/.van-grid-item__content {
      padding: 0 0;
    }
  }
}
</style>
